export const BOT_URL = " https://t.me/wukobot/app";

export const TWITTER_URL = "https://x.com/WuKongonchain";
export const YOUTUBE_URL = "https://www.youtube.com/@wukongapp";
export const TELEGRAM_URL = "https://t.me/WuKong_Community";
export const INSTAGRAM_URL = "https://www.instagram.com/wukongapp/";





import styles from "./S2.module.scss";
import { Flex, Image } from "@chakra-ui/react";
import logoImage from "../../assets/images/s2/logo.png";
import twitterImage from "../../assets/images/s2/twitter.png";
import youtubeImage from "../../assets/images/s2/youtube.png";
import telegramImage from "../../assets/images/s2/telegram.png";
import instagramImage from "../../assets/images/s2/instagram.png";
import wukong2Image from "../../assets/images/s2/wukong2.png";
// import seasonSvg from "../../assets/images/s2/season.svg";
// import twoSvg from "../../assets/images/s2/2.svg";
import s2Image from "../../assets/images/s2/s2.png";
import { useEffect, useRef } from "react";
// import CustomButton from "../UI/CButton/CButton";
import * as links from "./utils";

export default function S2() {
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    console.log("videoRef", videoRef.current);

    if (videoRef.current) {
      videoRef.current.muted = true;
      videoRef.current.play();
    }
  }, []);
  return (
    <Flex className={styles.container}>
      <Flex className={styles.bgContainer}>
        <video
          ref={videoRef}
          autoPlay
          loop
          muted
          playsInline
          className="background-video"
        >
          <source src={"mp4/particle.mp4"} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Flex>

      <Image src={wukong2Image} alt="wukong2" className={styles.wukongImage} />

      <Image src={s2Image} alt="s2" className={styles.s2Image} />

      <Flex className={styles.content}>
        <Flex className={styles.header}>
          <Image src={logoImage} alt="logo" className={styles.logoImage} />

          <Flex className={styles.socialImageContainer}>
            <Image
              src={twitterImage}
              alt="twitter"
              className={styles.socialImage}
              onClick={() => {
                window.open(links.TWITTER_URL, "_blank");
              }}
            />
            <Image
              src={youtubeImage}
              alt="youtube"
              className={styles.socialImage}
              onClick={() => {
                window.open(links.YOUTUBE_URL, "_blank");
              }}
            />
            <Image
              src={telegramImage}
              alt="telegram"
              className={styles.socialImage}
              onClick={() => {
                window.open(links.TELEGRAM_URL, "_blank");
              }}
            />
            <Image
              src={instagramImage}
              alt="instagram"
              className={styles.socialImage}
              onClick={() => {
                window.open(links.INSTAGRAM_URL, "_blank");
              }}
            />
          </Flex>
        </Flex>
        <Flex
          className={styles.joinButtonWrapper}
          onClick={() => {
            window.open(links.BOT_URL, "_blank");
          }}
        >
          <Flex className={styles.joinButtonContent}>
            <Flex className={styles.joinButton}>Launch WUKONG on Telegram</Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
